<template>
  <div>
    <div class="header">账户安全</div>
    <div class="nav"></div>
    <div class="content">
      <div class="li">
        <div class="li-header">
          <span class="sqr">
            <img src="../../assets/login_pic.png" alt="">
          </span>
          <span class="sqrc">登录密码</span>
        </div>
        <div class="li-content">
          <span>为保证您的账号安全<br> 建议您定期更改密码以保护账户安全</span>
        </div>
        <div class="li-footer">
          <a-button @click="updateLoginPassword">修改密码</a-button>
          <a-button @click="resetLooPwd" style="margin-left:10px">重置密码</a-button>
        </div>
      </div>
      <div class="li">
        <div class="li-header">
          <span class="sqr">
            <img src="../../assets/jy_pic.png" alt="">
          </span>
          <span class="sqrc">交易密码</span>
        </div>
        <div class="li-content">
          <span>启用交易密码功能<br> 为您资产账户安全加把锁</span>
        </div>
        <div class="li-footer">
          <a-button @click="updateJyPwd">修改密码</a-button>
          <a-button @click="resetPwd" style="margin-left:10px">重置密码</a-button>
        </div>
        <a-modal
          title="信息提示"
          :visible="visible"
          @ok="handleOk"
          @cancel="handleCancel"
        >
          <div class="common-msg"><a-icon style="color:#51c513;font-size:50px" theme="filled" type="check-circle" /></div>
          <div class="common-msg" style="margin-top:15px;font-size:20px">您的密码已重置！</div>
          <div class="common-msg" style="margin-top:15px;">我们已向您注册邮箱 <span style="color:#f15451">{{email}}</span> 发送了重置密码，<br>请登录注册邮箱查收。</div>
        </a-modal>
      </div>
      <div class="li" v-if="isBindWxCode==='false'">
        <div class="li-header">
          <span class="sqr">
            <img src="../../assets/weixin_pic.png" alt="">
          </span>
          <span class="sqrc">微信登录</span>
        </div>
        <div class="li-content">
          <span>未设置<br>可通过微信扫码绑定</span>
        </div>
        <div class="li-footer">
          <a-button style="margin-left:-100px" @click="bindWx">绑定微信</a-button>
        </div>
        <a-modal
          title="信息提示"
          :visible="visibleWx"
          @ok="handleOkWx"
          @cancel="handleCancelWx"
        >
          <div class="wx-code"><div id="qrcode" ref="qrcode"></div></div>
          <div class="wx-content-desc">请用微信扫描以上二维码进行绑定</div>
          <div class="wx-code"><a-button @click="getCodeUrl" type="link">刷新二维码</a-button></div>
        </a-modal>
      </div>
      <div class="li" v-if="isBindWxCode==='true'">
        <div class="li-header">
          <span class="sqr">
            <img src="../../assets/weixin_pic.png" alt="">
          </span>
          <span class="sqrc">微信登录</span>
        </div>
        <div class="li-content">
          <span>已设置<br>可通过微信扫码登录</span>
        </div>
        <div class="li-footer">
          <a-button style="margin-left:-100px" @click="returnBindWx">解绑微信</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  resetPwd,
  getUserBindEmail,
  resetPassword,
  getBindRQCode,
  checkBindWxMq,
  unBindWxMq,
  isChekSyS
} from '@/api/api'
import Vue from 'vue'
import QRCode from 'qrcodejs2'
import { USER_INFO } from "@/store/mutation-types";
export default {
  data () {
    return {
      userInfo: '',
      email: '',
      visible: false,
      visibleWx: false,
      qrcode: null,
      wxCodeTimer: null,
      intervalTimer: null,
      isReget: false,
      wxCodeKey: '',
      isBindWxCode: "false"
    }
  },
  created () {
    this.checkBindWxMq()
  },
  methods: {
    // 修改登录密码
    updateLoginPassword() {
      this.$router.push('/updateLoginPassword')
    },
    // 修改交易密码
    updateJyPwd() {
      this.$router.push('/updateJyPwd')
    },
    // 重置登录密码
    resetLooPwd () {
      let uerInfo = Vue.ls.get(USER_INFO)
      let that = this
      this.$confirm({
        title: '信息提示',
        content: '确认需要重置登录密码？',
        onOk() {
          return new Promise((resolve, reject) => {
            resetPassword(uerInfo.taxUserLoginId).then(res=>{
            if (res.success) {
              getUserBindEmail().then(ress=>{
                that.email = ress.message
                that.visible = true
              })
              that.$message.success(res.message)
              resolve()
            } else {
              that.$message.error(res.message)
              resolve()
            }
          })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 重置交易密码
    resetPwd () {
      let that = this
      this.$confirm({
        title: '信息提示',
        content: '确认需要重置交易密码？',
        onOk() {
          return new Promise((resolve, reject) => {
            resetPwd().then(res=>{
            if (res.success) {
              getUserBindEmail().then(ress=>{
                that.email = ress.message
                that.visible = true
              })
              that.$message.success(res.message)
              resolve()
            } else {
              this.$message.error(res.message)
              resolve()
            }
          })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    handleOk(e) {
      this.handleCancel()
    },
    handleCancel(e) {
      this.visible = false;
    },
    // 查询用户是否绑定微信二维码
    checkBindWxMq () {
      checkBindWxMq().then(res => {
        if (res.success) {
          this.isBindWxCode = res.result.isCheck
        }
      })
    },
    // 生成二维码
    makeQrcode (text) {
      if(this.qrcode){
        this.qrcode.clear();
        this.qrcode.makeCode(text);
      }else{
        this.qrcode = new QRCode('qrcode',{
          width: 282, // 设置宽度，单位像素
          height: 282, // 设置高度，单位像素
          text  // 设置二维码内容或跳转地址
        })
      }
    },
    // 获取二维码链接
    getCodeUrl () {
      getBindRQCode().then(res => {
        let url = res.result.wxMpQrCodeTicket.url
        this.wxCodeKey = res.result.key
        this.makeQrcode(url)
      })
    },
    // 校验扫描二维码是否成功
    validWxCodeSuccess () {
      this.intervalTimer = setInterval(() => {
        isChekSyS(this.wxCodeKey).then(res => {
          // console.log('检验是否成功',res)
          if (res.result.isCheck==='true') {
            // clearInterval(this.intervalTimer)
            this.checkBindWxMq()
            this.handleCancelWx()
          }
        })
      },1000)
    },
    // 绑定微信
    bindWx () {
      this.visibleWx = true
      this.getCodeUrl();
      this.validWxCodeSuccess()
    },
    handleOkWx(e) {
      this.handleCancelWx()
    },
    // 关闭微信二维码
    handleCancelWx(e) {
      if (this.wxCodeTimer!==null) {
        clearTimeout(this.wxCodeTimer)
      }
      if (this.intervalTimer!==null) {
        clearInterval(this.intervalTimer)
      }
      this.visibleWx = false;
    },
    // 解绑微信
    returnBindWx () {
      let that = this
      this.$confirm({
        title: '解除绑定?',
        content: '解除绑定之后将不能使用微信扫码登录！确定解除绑定吗？',
        onOk() {
          return new Promise((resolve, reject) => {
            unBindWxMq().then(res => {
              if (res.success) {
                that.$message.success(res.message)
                that.checkBindWxMq()
                that.qrcode = null
                resolve()
              } else {
                that.$message.error(res.message)
                reject()
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    }
  }
}
</script>
<style scoped lang="less">
.header{
  color: #283039;
  font-size: 18px;
  font-weight: bold;
}
.nav{
  margin: 15px 0 40px 0;
  color: #283039;
  font-size: 14px;
}
.content{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 900px;
  .li{
    width: 430px;
    height: 228px;
    background: #FFFFFF;
    box-shadow: 0px 0px 30px 0px #ECEEF3;
    border-radius: 6px;
    padding: 40px 46px 23px 46px;
    margin-bottom: 20px;
    .li-header{
      display: flex;
      align-items: center;
      .sqr{
        width: 46px;
        height: 46px;
        // background: #FFFFFF;
        // border: 2px solid #909090;
        // border-radius: 10px;
        margin-right: 29px;
        img{
          width: 46px;
          height: 46px;
        }
      }
      .sqrc{
        color: #283039;
        font-size: 24px;
        font-weight: bold;
      }
    }
    .li-content{
      color: #9CA0AD;
      font-size: 14px;
      margin: 8px 0 30px 75px;
    }
    .li-footer{
      text-align: center;
    }
  }
}
.common-msg{
  text-align: center;
}
.wx-code{
  display: flex;
  justify-content: center;
}
.wx-content-desc{
  margin: 20px 0;
  text-align: center;
}
</style>